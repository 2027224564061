import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as glob from '../../shared/global';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MasterService {

    constructor(private _httpClient: HttpClient) {
    }
    refreshToken(): Observable<HttpResponse<any>> {
        return this._httpClient.post<any>(glob.BASE_URL + 'intelisys/refresh-token', {}, {observe: 'response'});
    }
}
